import { useState, useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { Permissions } from '../../../../utils/constants/permissions';
import { Api, Helpers } from '../../../../utils/helpers';
import {
  ResearchSearchType,
  ResearchDateRangeType,
  ResearchDataSource,
  PaymentConfirmationView,
  TransactionStatus
} from '../../../../utils/constants/enums';

const useResearchSearch = () => {
  const [queryParams] = useSearchParams();
  const [{ user }] = useAuthContext();
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [lookupFieldLoading, setLookupFieldLoading] = useState(false);
  const [showInactiveTransactions, setShowInactiveTransactions] = useState(false);
  const [showTransactionsWithFiles, setShowTransactionsWithFiles] = useState(false);
  const [pagedResult, setPagedResult] = useState(null);
  const [modalConfirmationParams, setModalConfirmationParams] = useState({
    brandId: user.activeBrand.id,
    source: ResearchDataSource.BRAND,
    confirmationNumber: null,
    viewType: PaymentConfirmationView.DEFAULT,
    canVoid: false,
    canChargeback: false
  });
  const rowView = useRef([]);
  const canViewReceipt = user.permissions.includes(Permissions.Research.VIEW_RECEIPT);
  const userCanVoid =
    user.permissions.includes(Permissions.Payment.VOID_SAME_DAY) &&
    user.permissions.includes(Permissions.Payment.VOID_BEFORE_ACH);
  const userCanChargeback = user.permissions.includes(Permissions.Payment.CHARGEBACK);

  const isRowViewed = (cpiId) => {
    return rowView.current.includes(cpiId);
  };

  const paymentSearchCustomers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return {
        id: customer.id,
        name: customer.name,
        transactions: customer.transactions.map((tx) => {
          return {
            id: tx.id,
            name: tx.name,
            status: tx.status,
            deleted: tx.deleted
          };
        })
      };
    });

  const defaultCustomerId =
    paymentSearchCustomers.length === 1 ? paymentSearchCustomers[0].id : null;

  const [searchParameters, setSearchParameters] = useState({
    pageSize: 20,
    page: 1,
    timestamp: null,
    brandId: user.activeBrand.id,
    searchType: ResearchSearchType.BASIC,
    confirmationNumber: '',
    emailAddress: '',
    customerId: defaultCustomerId,
    transactionId: null,
    dateRangeType: ResearchDateRangeType.TODAY,
    fromDate: null,
    toDate: null,
    payorName: '',
    phoneNumber: '',
    hasFiles: false,
    lookupValues: {}
  });
  const [lookupFields, setLookupFields] = useState([]);

  const transactions = paymentSearchCustomers
    .find((customer) => customer.id === searchParameters.customerId)
    ?.transactions.filter(
      (transaction) =>
        !transaction.deleted &&
        (showInactiveTransactions ||
          (!showInactiveTransactions && transaction.status !== TransactionStatus.INACTIVE))
    );

  const toggleInactiveTransactions = () => {
    //if hiding inactive transactions and the currently selected transaction
    //is inactive, clear the transaction selection
    if (
      showInactiveTransactions &&
      transactions?.some(
        (tx) => tx.id === searchParameters.transactionId && tx.status === TransactionStatus.INACTIVE
      )
    ) {
      updateSearchParams('transactionId', null);
    }
    setShowInactiveTransactions(!showInactiveTransactions);
  };

  const toggleTransactionsWithFiles = () => {
    updateSearchParams('hasFiles', !showTransactionsWithFiles);
    setShowTransactionsWithFiles(!showTransactionsWithFiles);
  };

  const updateSearchParams = (key, value, options) => {
    setFieldError(key, false);

    //handle clearing custom error messages based on which fields were changed
    switch (key) {
      case 'emailAddress':
      case 'confirmationNumber':
        setFieldError('basicSearchMissing', false);
        break;
      case 'dateRangeType':
        setFieldError('customDateMissing', false);
        setFieldError('customDateInvalid', false);
        setFieldError('advancedFieldMissing', false);
        break;
      case 'payorName':
      case 'phoneNumber':
      case 'transactionId':
        setFieldError('advancedFieldMissing', false);
        break;
      case 'fromDate':
      case 'toDate':
        setFieldError('customDateInvalid', false);
        break;
    }

    let params = { ...searchParameters };
    if (options?.numbersOnly) {
      value = value?.replace(/\D/g, '') ?? '';
    }
    //if the date range type is changed, reset the To and From fields to be blank
    if (key === 'dateRangeType') {
      params.toDate = null;
      params.fromDate = null;
    }
    params[key] = value;
    setSearchParameters(params);
  };

  const updateLookupParams = (key, value) => {
    let params = { ...searchParameters };
    params.lookupValues[key] = value;
    setSearchParameters(params);
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const runBasicSearch = () => {
    let formErrors = {};
    if (
      !Helpers.isNullOrWhitespace(searchParameters.emailAddress) &&
      !Helpers.isValidEmail(searchParameters.emailAddress)
    ) {
      formErrors.emailAddress = true;
    }

    if (
      Helpers.isNullOrWhitespace(searchParameters.confirmationNumber) &&
      Helpers.isNullOrWhitespace(searchParameters.emailAddress)
    ) {
      formErrors.basicSearchMissing = true;
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    let params = { ...searchParameters };
    params.page = 1;
    params.searchType = ResearchSearchType.BASIC;
    params.timestamp = Date.now();
    setSearchParameters(params);
  };

  const runAdvancedSearch = () => {
    let formErrors = {};

    if (!searchParameters.customerId) {
      formErrors.customerId = true;
      setErrors(formErrors);
      return false;
    }

    //make sure they have at least one of the fields filled in
    if (
      Helpers.isNullOrWhitespace(searchParameters.transactionId) &&
      Helpers.isNullOrWhitespace(searchParameters.fromDate) &&
      Helpers.isNullOrWhitespace(searchParameters.toDate) &&
      Helpers.isNullOrWhitespace(searchParameters.payorName) &&
      Helpers.isNullOrWhitespace(searchParameters.phoneNumber)
    ) {
      formErrors.advancedFieldMissing = true;
      setErrors(formErrors);
      return false;
    }

    //if they selected custom date, they need to fill in the dates
    if (searchParameters.dateRangeType === ResearchDateRangeType.CUSTOM) {
      if (!searchParameters.fromDate || !searchParameters.toDate) {
        formErrors.customDateMissing = true;
      } else {
        //check to make sure dates are valid and do not include 'today'
        const currentDate = Helpers.dateWithoutTime(new Date());
        const testFromDate = Helpers.tryParseDate(`${searchParameters.fromDate} 00:00:00`, null);
        const testToDate = Helpers.tryParseDate(`${searchParameters.toDate} 00:00:00`, null);

        if (
          testFromDate === null ||
          testToDate === null ||
          testFromDate.getTime() === currentDate.getTime() ||
          testToDate.getTime() === currentDate.getTime() ||
          (testFromDate < currentDate && testToDate > currentDate)
        ) {
          formErrors.customDateInvalid = true;
        }
      }
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    let params = { ...searchParameters };
    params.page = 1;
    params.searchType = ResearchSearchType.ADVANCED;
    params.timestamp = Date.now();
    setSearchParameters(params);
  };

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  const resetBasicSearch = () => {
    let params = { ...searchParameters };
    params.confirmationNumber = '';
    params.emailAddress = '';
    setSearchParameters(params);
    setPagedResult(null);
  };

  const resetAdvancedSearch = () => {
    let params = { ...searchParameters };
    params.transactionId = null;
    params.dateRangeType = ResearchDateRangeType.TODAY;
    params.fromDate = null;
    params.toDate = null;
    params.payorName = '';
    params.phoneNumber = '';
    params.lookupValues = {};
    setSearchParameters(params);
    setPagedResult(null);
  };

  const handleConfirmationLink = (
    e,
    cpiId,
    source,
    confirmationNumber,
    canVoid,
    canChargeback,
    viewType
  ) => {
    //track this cpiId as viewed
    if (!rowView.current.includes(cpiId)) {
      rowView.current.push(cpiId);
    }

    e.preventDefault();
    let confParams = { ...modalConfirmationParams };
    confParams.source = source;
    confParams.confirmationNumber = confirmationNumber;
    confParams.viewType = viewType;
    confParams.canVoid = canVoid;
    confParams.canChargeback = canChargeback;
    setModalConfirmationParams(confParams);
  };

  const closeModalConfirmation = () => {
    let confParams = { ...modalConfirmationParams };
    confParams.confirmationNumber = null;
    setModalConfirmationParams(confParams);
  };

  const confirmationViewChange = (viewType) => {
    let confParams = { ...modalConfirmationParams };
    confParams.viewType = viewType;
    setModalConfirmationParams(confParams);
  };

  const reloadSearch = () => {
    let searchParams = { ...searchParameters };
    searchParams.timestamp = Date.now();
    setSearchParameters(searchParams);
  };

  const handleSearchKeyDown = (event, searchType) => {
    if (event.key === 'Enter') {
      if (searchType === ResearchSearchType.BASIC) {
        runBasicSearch();
      } else {
        runAdvancedSearch();
      }
    }
  };

  const search = useCallback(async () => {
    setApiError('');
    if (searchParameters.timestamp === null) {
      return;
    }
    setLoading(true);

    const searchParams = JSON.stringify(searchParameters);
    const apiResponse = await Api.post(`/data/Research/Search`, searchParams);
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setApiError(
        `${apiResponse.status.statusCode} - ${apiResponse.status.statusDescription}
        ${apiResponse.errors.join(', ')}`
      );
      setLoading(false);
      return;
    }

    setPagedResult(apiResponse.response);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters.searchType, searchParameters.page, searchParameters.timestamp]);

  const handleQuerySearch = () => {
    //check for query params
    const queryConfNumber = queryParams.get('confirmationNumber');
    const queryEmail = queryParams.get('email');

    //if no query parameters provided, no need to continue
    if (!queryConfNumber && !queryEmail) {
      return;
    }

    let searchParams = { ...searchParameters };
    searchParams.searchType = ResearchSearchType.BASIC;
    searchParams.confirmationNumber = queryConfNumber ?? '';
    searchParams.emailAddress = queryEmail ?? '';
    searchParams.timestamp = Date.now();
    setSearchParameters(searchParams);
  };

  useEffect(() => {
    //automatically trigger search if page changes or type changes
    search();
  }, [search, searchParameters.page, searchParameters.searchType, searchParameters.timestamp]);

  useEffect(() => {
    //automatically trigger lookup field load if transaction id changes
    const loadLookupFields = async () => {
      if (!searchParameters.transactionId) {
        let params = { ...searchParameters };
        params.lookupValues = {};
        setSearchParameters(params);
        setLookupFields([]);
        return;
      }
      setLookupFieldLoading(true);

      const lookupParams = {
        brandId: searchParameters.brandId,
        transactionId: searchParameters.transactionId
      };

      const apiResponse = await Api.post(
        `/data/Research/GetLookupFields`,
        JSON.stringify(lookupParams)
      );
      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        setLookupFieldLoading(false);
        return;
      }

      setLookupFields(apiResponse.response);
      let params = { ...searchParameters };

      //create lookup value object used for searches
      for (const field of apiResponse.response) {
        params.lookupValues[field.dataColumnName] = '';
      }
      setSearchParameters(params);
      setLookupFieldLoading(false);
    };
    loadLookupFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters.transactionId]);

  useEffect(() => {
    //if customer id changes, automatically reset transaction id
    let params = { ...searchParameters };
    params.transactionId = null;
    params.lookupValues = {};
    setSearchParameters(params);
    setLookupFields([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters.customerId]);

  useEffect(() => {
    //if date range or transaction changes and customer is already selected, run advanced search
    if (!searchParameters.customerId) {
      return;
    }
    runAdvancedSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters.transactionId, searchParameters.dateRangeType]);

  useEffect(() => {
    //if brand changes, reset modal and search results
    setModalConfirmationParams({
      brandId: user.activeBrand.id,
      source: ResearchDataSource.BRAND,
      confirmationNumber: null,
      viewType: PaymentConfirmationView.DEFAULT,
      canVoid: false,
      canChargeback: false
    });
    setSearchParameters((sp) => {
      return { ...sp, brandId: user.activeBrand.id, customerId: defaultCustomerId };
    });
    setPagedResult(null);
  }, [user.activeBrand.id]);

  useEffect(() => {
    handleQuerySearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    lookupFieldLoading,
    lookupFields,
    errors,
    apiError,
    paymentSearchCustomers,
    transactions,
    pagedResult,
    searchParameters,
    modalConfirmationParams,
    canViewReceipt,
    userCanVoid,
    userCanChargeback,
    showInactiveTransactions,
    showTransactionsWithFiles,
    updateSearchParams,
    updateLookupParams,
    runBasicSearch,
    runAdvancedSearch,
    changePage,
    resetBasicSearch,
    resetAdvancedSearch,
    setModalConfirmationParams,
    handleConfirmationLink,
    closeModalConfirmation,
    handleSearchKeyDown,
    confirmationViewChange,
    reloadSearch,
    isRowViewed,
    toggleInactiveTransactions,
    toggleTransactionsWithFiles
  };
};

export default useResearchSearch;

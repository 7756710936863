import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { Api, Helpers } from '../../../../utils/helpers';
import {
  ReportType,
  ReportDateRangeType,
  ReportResponseType,
  ScreenViewType,
  TransactionStatus
} from '../../../../utils/constants/enums';
import { availableReports } from './availableReports';

const useReportManager = () => {
  const [queryParams] = useSearchParams();
  const queryReportId = Helpers.tryParseInt(queryParams.get('report'), 0);

  const defaultReport = availableReports.find((r) => r.id === queryReportId);
  const defaultReportType = defaultReport ? queryReportId : ReportType.UNKNOWN;
  const defaultShowDateSelect = defaultReport?.dateSelect ?? true;
  const defaultShowCustomerSelect = defaultReport?.customerSelect ?? true;
  const defaultShowInactiveTransactions = defaultReport?.defaultShowInactiveTransactions ?? false;

  const [{ user }] = useAuthContext();
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [viewType, setViewType] = useState(ScreenViewType.DEFAULT);
  const [showDateSelect, setShowDateSelect] = useState(defaultShowDateSelect);
  const [showCustomerSelect, setShowCustomerSelect] = useState(defaultShowCustomerSelect);
  const [displayResults, setDisplayResults] = useState('');
  const [showInactiveTransactions, setShowInactiveTransactions] = useState(
    defaultShowInactiveTransactions
  );
  const [showDeletedTransactions, setShowDeletedTransactions] = useState(false);

  const hasAllCustomerAccess =
    user.resources.brands
      .find((brand) => brand.id === user.activeBrand.id)
      ?.customers.some((x) => x.id === 0) ?? false;

  const customers = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.map((customer) => {
      return { id: customer.id, name: customer.name };
    });
  const defaultCustomer = customers.length === 1 ? customers[0] : null;

  const [downloadReportParameters, setDownloadReportParameters] = useState(null);
  const [reportParameters, setReportParameters] = useState({
    brandId: user.activeBrand.id,
    reportType: defaultReportType,
    customerId: defaultCustomer?.id,
    dateRangeType: ReportDateRangeType.TODAY,
    fromDate: null,
    toDate: null,
    transactionIds: [],
    responseType: ReportResponseType.HTML
  });

  const allowedReports = availableReports.filter((report) => {
    const hasRequiredPermission = report.requiredPermissions.every((p) =>
      user.permissions.includes(p)
    );
    const hasRequiredCustomerAccess =
      !hasAllCustomerAccess || (hasAllCustomerAccess && report.accessibleToAllCustomerUser);

    return hasRequiredPermission && hasRequiredCustomerAccess;
  });

  const transactions = user.userResources.brands
    .find((brand) => brand.id === user.activeBrand.id)
    .customers.find((customer) => customer.id === reportParameters.customerId)
    ?.transactions.filter(
      (transaction) =>
        (showInactiveTransactions ||
          (!showInactiveTransactions && transaction.status !== TransactionStatus.INACTIVE)) &&
        (showDeletedTransactions || (!showDeletedTransactions && !transaction.deleted))
    )
    .map((transaction) => {
      return {
        id: transaction.id,
        name: transaction.name,
        status: transaction.status,
        deleted: transaction.deleted
      };
    });

  const updateReportParams = (key, value, options) => {
    //handle clearing custom error messages based on which fields were changed
    switch (key) {
      case 'dateRangeType':
        setFieldError('customDateMissing', false);
        setFieldError('customDateInvalid', false);
        break;
      case 'fromDate':
      case 'toDate':
        setFieldError('customDateInvalid', false);
        break;
      default:
        setFieldError(key, false);
        break;
    }

    let params = { ...reportParameters };
    if (options?.numbersOnly) {
      value = value.replace(/\D/g, '');
    }
    params[key] = value;
    //if the date range type is changed, reset the To and From fields to be blank
    if (key === 'dateRangeType') {
      params.toDate = null;
      params.fromDate = null;
    }
    setReportParameters(params);

    if (key === 'reportType') {
      const report = allowedReports.find((r) => r.id === value);
      if (report) {
        setShowDateSelect(report.dateSelect);
        setShowCustomerSelect(report.customerSelect);
        setShowInactiveTransactions(report.defaultShowInactiveTransactions);
      }
    }
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const updateTransaction = (transactionId) => {
    transactionId = parseInt(transactionId);
    let params = { ...reportParameters };
    if (params.transactionIds.includes(transactionId)) {
      params.transactionIds = params.transactionIds.filter((t) => t !== transactionId);
    } else {
      params.transactionIds.push(transactionId);
    }
    setReportParameters(params);
  };

  const hasTransaction = (transactionId) => {
    transactionId = parseInt(transactionId);
    return reportParameters.transactionIds.includes(transactionId);
  };

  const toggleInactiveTransactions = () => {
    //if hiding inactive transactions
    //unselect any that are inactive
    let params = { ...reportParameters };
    params.transactionIds = params.transactionIds.filter((tx) =>
      transactions.some(
        (transaction) => transaction.id === tx && transaction.status !== TransactionStatus.INACTIVE
      )
    );
    setReportParameters(params);
    setShowInactiveTransactions(!showInactiveTransactions);
  };

  const toggleDeletedTransactions = () => {
    //if hiding deleted transactions, unselect any that are deleted
    let params = { ...reportParameters };
    params.transactionIds = params.transactionIds.filter((tx) =>
      transactions.some((transaction) => transaction.id === tx && !transaction.deleted)
    );
    setReportParameters(params);
    setShowDeletedTransactions(!showDeletedTransactions);
  };

  const selectAllTransactions = () => {
    let params = { ...reportParameters };
    if (hasAllTransactions()) {
      params.transactionIds = [];
    } else {
      const allTransactions = transactions.map((t) => t.id);
      params.transactionIds = allTransactions;
    }
    setReportParameters(params);
  };

  const hasAllTransactions = () => {
    return transactions.length === reportParameters.transactionIds.length;
  };

  const runReport = async () => {
    setApiError('');
    let formErrors = {};
    if (reportParameters.reportType === ReportType.UNKNOWN) {
      formErrors.reportType = true;
    }

    if (showCustomerSelect && !reportParameters.customerId) {
      formErrors.customerId = true;
    }

    if (showCustomerSelect && reportParameters.transactionIds.length <= 0) {
      formErrors.transactionIds = true;
    }

    //if they selected custom date, they need to fill in the dates
    if (showDateSelect && reportParameters.dateRangeType === ReportDateRangeType.CUSTOM) {
      if (!reportParameters.fromDate || !reportParameters.toDate) {
        formErrors.customDateMissing = true;
      } else {
        //check to make sure dates are valid and do not include 'today'
        const currentDate = Helpers.dateWithoutTime(new Date());
        const testFromDate = Helpers.tryParseDate(`${reportParameters.fromDate} 00:00:00`, null);
        const testToDate = Helpers.tryParseDate(`${reportParameters.toDate} 00:00:00`, null);

        if (
          testFromDate === null ||
          testToDate === null ||
          testFromDate.getTime() === currentDate.getTime() ||
          testToDate.getTime() === currentDate.getTime() ||
          (testFromDate < currentDate && testToDate > currentDate)
        ) {
          formErrors.customDateInvalid = true;
        }
      }
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    setLoading(true);

    const reportParams = { ...reportParameters };
    reportParams.responseType = ReportResponseType.HTML;
    const apiResponse = await Api.post(`/data/Reporting/RunReport`, JSON.stringify(reportParams));

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setApiError(
        `${apiResponse.status.statusCode} - ${apiResponse.status.statusDescription}
        ${apiResponse.errors.join(', ')}`
      );
      setDisplayResults('');
      setLoading(false);
      return;
    }

    setDownloadReportParameters(reportParameters);
    setDisplayResults(apiResponse.response);
    setLoading(false);
  };

  const getReportDownload = async (type) => {
    const reportParams = { ...downloadReportParameters };
    reportParams.responseType = type;
    const apiResponse = await Api.post(`/data/Reporting/RunReport`, JSON.stringify(reportParams));
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setApiError(
        `${apiResponse.status.statusCode} - ${apiResponse.status.statusDescription}
        ${apiResponse.errors.join(', ')}`
      );
      setLoading(false);
      return;
    }
    const downloadUrl = `${process.env.REACT_APP_BFF_API_URL}/data/Reporting/DownloadReport/${apiResponse.response}`;
    window.open(downloadUrl, '_blank');
  };

  const toggleReportView = () => {
    setViewType(
      viewType === ScreenViewType.DEFAULT ? ScreenViewType.FULLSCREEN : ScreenViewType.DEFAULT
    );
  };

  useEffect(() => {
    //if customer id changes, reset the transactions
    let params = { ...reportParameters };
    params.transactionIds = [];
    setReportParameters(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportParameters.customerId]);

  useEffect(() => {
    //if brand id changes, reset results
    setDisplayResults('');

    let params = { ...reportParameters };
    params.brandId = user.activeBrand.id;
    params.customerId = defaultCustomer?.id;
    params.transactionIds = [];
    setReportParameters(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.activeBrand.id]);

  return {
    loading,
    errors,
    apiError,
    displayResults,
    showDateSelect,
    showCustomerSelect,
    defaultCustomer,
    customers,
    transactions,
    reportParameters,
    allowedReports,
    viewType,
    showInactiveTransactions,
    showDeletedTransactions,
    setDisplayResults,
    updateReportParams,
    updateTransaction,
    hasTransaction,
    selectAllTransactions,
    hasAllTransactions,
    runReport,
    getReportDownload,
    toggleReportView,
    toggleInactiveTransactions,
    toggleDeletedTransactions
  };
};

export default useReportManager;

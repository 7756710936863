import { useState, useCallback, useEffect, useRef } from 'react';
import { useAuthContext } from '../../../../context/provider/AuthContext';
import { Api } from '../../../../utils/helpers';
import { Helpers } from '../../../../utils/helpers';

const useBillSearch = () => {
  const [{ user }] = useAuthContext();
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);
  const [showWildcardSearch, setShowWildcardSearch] = useState(false);
  const [transactionFields, setTransactionFields] = useState([]);
  const [pagedResult, setPagedResult] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [apiDetailError, setApiDetailError] = useState('');
  const [billDetails, setBillDetails] = useState(null);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [wildcardSearchFields, setWildcardSearchFields] = useState({
    keywords: '',
    amountDue: null,
    amountDueFuzziness: 0
  });
  const scrollToRef = useRef(null);

  const defaultFieldsVisible = 4;

  const rowView = useRef([]);
  const isRowViewed = (tRowId) => {
    return rowView.current.includes(tRowId);
  };

  const [billSearchCustomers, setBillSearchCustomers] = useState([]);
  const [searchParameters, setSearchParameters] = useState({
    pageSize: 20,
    page: 1,
    timestamp: null,
    brandId: user.activeBrand.id,
    customerId: null,
    transactionId: null
  });

  const updateWildcardField = (key, value) => {
    setWildcardSearchFields((wcsFields) => {
      const wildcardSearch = { ...wcsFields };
      if (key === 'amountDue') {
        value = !Helpers.isNullOrWhitespace(value) ? Helpers.tryParseFloat(value, 0) : null;
      } else if (key === 'amountDueFuzziness') {
        value = !Helpers.isNullOrWhitespace(value) ? Helpers.tryParseInt(value, 0) : null;
      }
      wildcardSearch[key] = value;
      return wildcardSearch;
    });
  };

  const getDefaultTransaction = (customers, customerId) => {
    if (Helpers.isNullOrWhitespace(customerId)) return null;

    const customer = customers.find((c) => c.customerId === customerId);
    const customerTransactions =
      customer?.transactions?.map((tx) => {
        return {
          transactionId: tx.transactionId,
          transactionName: tx.transactionName
        };
      }) ?? [];

    if (customerTransactions.length === 1) {
      return customerTransactions[0].transactionId;
    } else if (customerTransactions.length > 1) {
      return 0; //all
    } else {
      return null;
    }
  };

  const updateSearchParams = (key, value) => {
    switch (key) {
      case 'customerId':
        setSearchParameters((params) => {
          params.customerId = value;
          params.transactionId = getDefaultTransaction(billSearchCustomers, params.customerId);
          return params;
        });
        setPagedResult(null);
        setFieldError('customerId', false);
        return;
      case 'transactionId':
        setPagedResult(null);
        setFieldError('transactionId', false);
        break;
      default:
        setFieldError(key, false);
    }

    setSearchParameters((params) => {
      params[key] = value;
      return params;
    });
  };

  const updateFieldValue = (rowId, value) => {
    setFieldError('fieldData', false);
    const txFields = [...transactionFields];
    const rowToUpdate = txFields.find((x) => x.rowId === rowId);
    if (rowToUpdate) {
      rowToUpdate.value = value;
      setTransactionFields(txFields);
    }
  };

  const updateFuzzinessValue = (rowId, value) => {
    const txFields = [...transactionFields];
    const rowToUpdate = txFields.find((x) => x.rowId === rowId);
    if (rowToUpdate) {
      rowToUpdate.fuzziness = Helpers.tryParseInt(value, 0);
      setTransactionFields(txFields);
    }
  };

  const calculateFuzziness = (value, fuzziness) => {
    const baseValue = Helpers.tryParseFloat(value, 0);
    const offset = (baseValue * fuzziness) / 100;
    const startValue = Math.floor(baseValue - offset);
    const endValue = Math.ceil(baseValue + offset);
    return `${Helpers.formatCurrency(startValue, 0)} to ${Helpers.formatCurrency(endValue, 0)}`;
  };

  const toggleField = (rowId) => {
    setFieldError('fieldData', false);
    const txFields = [...transactionFields];
    const rowToUpdate = txFields.find((x) => x.rowId === rowId);
    if (rowToUpdate) {
      rowToUpdate.visible = !rowToUpdate.visible;
      setTransactionFields(txFields);
    }
  };

  const getFieldCounts = () => {
    const visibleCount = transactionFields.filter((x) => x.visible).length;
    const totalCount = transactionFields.length;
    return `${visibleCount} of ${totalCount} selected`;
  };

  const setFieldError = (field, hasError) => {
    let errorsToUpdate = { ...errors };
    errorsToUpdate[field] = hasError;
    setErrors(errorsToUpdate);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      runSearch();
    }
  };

  const runSearch = () => {
    let formErrors = {};

    if (!searchParameters.customerId) {
      formErrors.customerId = true;
      setErrors(formErrors);
      return false;
    }

    if (searchParameters.transactionId === null) {
      formErrors.transactionId = true;
      setErrors(formErrors);
      return false;
    }

    if (searchParameters.transactionId === 0) {
      if (
        Helpers.isNullOrWhitespace(wildcardSearchFields.keywords) &&
        Helpers.isNullOrWhitespace(wildcardSearchFields.amountDue)
      ) {
        formErrors.wildcardSearchData = true;
        setErrors(formErrors);
        return false;
      }
    } else {
      const hasFieldData = transactionFields.some(
        (field) => field.visible && field.value.trim() !== ''
      );
      if (!hasFieldData) {
        formErrors.fieldData = true;
        setErrors(formErrors);
        return false;
      }
    }

    setErrors(formErrors);

    //do we have any errors?
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    let params = { ...searchParameters };
    params.page = 1;
    params.timestamp = Date.now();
    setSearchParameters(params);
  };

  const changePage = (page) => {
    let params = { ...searchParameters };
    params.page = page;
    setSearchParameters(params);
  };

  const closeModal = () => {
    setShowDetailsModal(false);
  };
  const closeFieldsModal = () => {
    setShowFieldsModal(false);
  };

  const search = useCallback(async () => {
    setApiError('');

    const allTransactions =
      billSearchCustomers
        .find((cust) => cust.customerId === searchParameters.customerId)
        ?.transactions.map((tx) => tx.transactionId) ?? [];

    const fields = transactionFields.filter((field) => field.visible && field.value.trim() !== '');

    const searchRequest = {
      page: searchParameters.page,
      pageSize: searchParameters.pageSize,
      brandId: user.activeBrand.id,
      customerId: searchParameters.customerId,
      transactionIds:
        searchParameters.transactionId !== 0 ? [searchParameters.transactionId] : allTransactions
    };

    if (searchParameters.transactionId === 0) {
      //all transaction wildcard search
      searchRequest.keywords = wildcardSearchFields.keywords;
      searchRequest.amountDue = wildcardSearchFields.amountDue;
      searchRequest.amountDueFuzziness = wildcardSearchFields.amountDueFuzziness;
    } else {
      //field search
      searchRequest.fields = fields;
    }

    //do we have enough info to search?
    if (
      searchRequest.customerId === null ||
      searchRequest.transactionIds.length === 0 ||
      (searchParameters.transactionId !== 0 && searchRequest.fields.length === 0) ||
      (searchParameters.transactionId === 0 &&
        Helpers.isNullOrWhitespace(wildcardSearchFields.keywords) &&
        wildcardSearchFields.amountDue === null)
    ) {
      return;
    }

    setLoading(true);

    const apiResponse = await Api.post(`/data/bill-search`, JSON.stringify(searchRequest));
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setApiError(
        `${apiResponse.status.statusCode} - ${apiResponse.status.statusDescription}
        ${apiResponse.errors.join(', ')}`
      );
      setLoading(false);
      return;
    }

    const fileKeyCount = apiResponse.response.results.reduce((acc, result) => {
      if (!acc.includes(result.fileKeyName)) {
        acc.push(result.fileKeyName);
      }
      return acc;
    }, []).length;

    apiResponse.response.fileKeyCount = fileKeyCount;
    apiResponse.response.fileKeyHeader =
      fileKeyCount > 1 ? 'Lookup' : apiResponse.response.results[0]?.fileKeyName ?? 'Lookup';

    setPagedResult(apiResponse.response);
    setLoading(false);
    scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.activeBrand.id,
    transactionFields,
    billSearchCustomers,
    searchParameters.page,
    searchParameters.timestamp,
    searchParameters.customerId,
    searchParameters.transactionId
  ]);

  const buildTransactionFields = (txFields) => {
    return txFields.map((field, index) => {
      return {
        ...field,
        rowId: index,
        visible: index < defaultFieldsVisible || field.isAmountDue,
        fuzziness: 0
      };
    });
  };

  const viewDetails = async (transactionId, tRowId) => {
    //track this row as viewed
    if (!rowView.current.includes(tRowId)) {
      rowView.current.push(tRowId);
    }

    setApiDetailError('');
    setModalLoading(true);
    setShowDetailsModal(true);

    const allTransactions =
      billSearchCustomers
        .find((cust) => cust.customerId === searchParameters.customerId)
        ?.transactions.map((tx) => tx.transactionId) ?? [];

    const detailRequest = {
      brandId: user.activeBrand.id,
      customerId: searchParameters.customerId,
      transactionIds:
        searchParameters.transactionId !== 0 ? [searchParameters.transactionId] : allTransactions
    };

    const apiResponse = await Api.post(
      `/data/bill-search/${transactionId}/${tRowId}`,
      JSON.stringify(detailRequest)
    );

    if (apiResponse.status.statusCode === 200 && apiResponse.response !== null) {
      setBillDetails(apiResponse.response);
    } else {
      setBillDetails(null);
      setApiDetailError('Unexpected error loading details.');
    }
    setModalLoading(false);
  };

  useEffect(() => {
    search();
  }, [searchParameters.page, searchParameters.timestamp]);

  useEffect(() => {
    const loadTransactionFields = async () => {
      setShowWildcardSearch(false);
      if (searchParameters.transactionId === 0) {
        setShowWildcardSearch(true);
        return;
      }

      if (searchParameters.customerId === null || searchParameters.transactionId === null) {
        setTransactionFields([]);
        return;
      }
      setLoadingFields(true);

      const allTransactions =
        billSearchCustomers
          .find((cust) => cust.customerId === searchParameters.customerId)
          ?.transactions.map((tx) => tx.transactionId) ?? [];

      const fieldRequest = {
        brandId: user.activeBrand.id,
        customerId: searchParameters.customerId,
        transactionIds:
          searchParameters.transactionId !== 0 ? [searchParameters.transactionId] : allTransactions
      };

      const apiResponse = await Api.post('/data/bill-search/fields', JSON.stringify(fieldRequest));
      if (apiResponse.status.statusCode === 200) {
        setTransactionFields(buildTransactionFields(apiResponse.response));
      } else {
        setTransactionFields([]);
      }
      setLoadingFields(false);
    };
    loadTransactionFields();
  }, [
    user.activeBrand.id,
    searchParameters.customerId,
    searchParameters.transactionId,
    billSearchCustomers
  ]);

  useEffect(() => {
    const loadAllCustomerTransactions = async () => {
      const customerIds =
        user.userResources.brands
          .find((brand) => brand.id === user.activeBrand.id)
          ?.customers.map((customer) => customer.id) ?? [];

      const txRequest = {
        brandId: user.activeBrand.id,
        customerIds: customerIds
      };
      const apiResponse = await Api.post(
        `/data/bill-search/transactions`,
        JSON.stringify(txRequest)
      );
      if (apiResponse.status.statusCode === 200 && apiResponse.response !== null) {
        const customerTxs = apiResponse.response;
        const defaultCustomerId = customerTxs.length === 1 ? customerTxs[0].customerId : null;

        setBillSearchCustomers(customerTxs);
        setSearchParameters((sp) => {
          const updateSp = { ...sp };
          sp.customerId = defaultCustomerId;
          sp.transactionId = getDefaultTransaction(customerTxs, defaultCustomerId);
          return updateSp;
        });
      } else {
        setBillSearchCustomers([]);
      }
    };
    loadAllCustomerTransactions();
  }, [user.activeBrand.id]);

  const resetSearch = () => {
    let params = { ...searchParameters };
    params.customerId = billSearchCustomers.length === 1 ? billSearchCustomers[0].customerId : null;
    params.transactionId = [];
    setSearchParameters(params);
    setPagedResult(null);
  };

  return {
    brandId: user.activeBrand.id,
    loading,
    errors,
    apiError,
    pagedResult,
    billSearchCustomers,
    searchParameters,
    loadingFields,
    transactionFields,
    modalLoading,
    showDetailsModal,
    billDetails,
    apiDetailError,
    showFieldsModal,
    defaultFieldsVisible,
    scrollToRef,
    wildcardSearchFields,
    showWildcardSearch,
    handleSearchKeyDown,
    viewDetails,
    closeModal,
    closeFieldsModal,
    setShowFieldsModal,
    updateSearchParams,
    resetSearch,
    isRowViewed,
    runSearch,
    changePage,
    updateFieldValue,
    updateFuzzinessValue,
    toggleField,
    getFieldCounts,
    calculateFuzziness,
    updateWildcardField
  };
};

export default useBillSearch;

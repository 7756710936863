import { Permissions } from '../../../../utils/constants/permissions';
import { ReportType } from '../../../../utils/constants/enums';

export const availableReports = [
  {
    id: ReportType.BANK_RECONCILIATION,
    requiredPermissions: [Permissions.Reports.BANK_RECONCILIATION],
    name: 'Bank Reconciliation',
    dateSelect: true,
    customerSelect: true,
    accessibleToAllCustomerUser: true,
    defaultShowInactiveTransactions: false
  },
  {
    id: ReportType.TRANSACTION_DETAIL,
    requiredPermissions: [Permissions.Reports.TRANSACTION_DETAIL],
    name: 'Transaction Detail',
    dateSelect: true,
    customerSelect: true,
    accessibleToAllCustomerUser: true,
    defaultShowInactiveTransactions: false
  },
  {
    id: ReportType.TRANSACTION_CHARGEBACK,
    requiredPermissions: [Permissions.Reports.TRANSACTION_CHARGEBACK],
    name: 'Transaction Detail (Chargeback)',
    dateSelect: true,
    customerSelect: true,
    accessibleToAllCustomerUser: true,
    defaultShowInactiveTransactions: false
  },
  {
    id: ReportType.BANK_DEPOSIT,
    requiredPermissions: [Permissions.Reports.BANK_DEPOSIT],
    name: 'Bank Deposit (Aggregate)',
    dateSelect: true,
    customerSelect: true,
    accessibleToAllCustomerUser: true,
    defaultShowInactiveTransactions: false
  },
  {
    id: ReportType.AUTOPAY_RECURRING,
    requiredPermissions: [Permissions.Reports.AUTOPAY_RECURRING],
    name: 'Current AutoPay/Recurring Plans',
    dateSelect: false,
    customerSelect: true,
    accessibleToAllCustomerUser: true,
    defaultShowInactiveTransactions: true
  },
  {
    id: ReportType.MID,
    requiredPermissions: [Permissions.Reports.MID],
    name: 'Bank Reconciliation by MID',
    dateSelect: true,
    customerSelect: false,
    accessibleToAllCustomerUser: false,
    defaultShowInactiveTransactions: false
  }
];
